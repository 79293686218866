import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, connectAuthEmulator } from 'firebase/auth';
import { getAuth } from 'firebase/auth';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

// import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: "AIzaSyBCOHhXgLnO3_jTRFAGTqBRfRLooDONKXo",
    authDomain: "condensemail.com",
    projectId: "hip-principle-412214",
    storageBucket: "hip-principle-412214.appspot.com",
    messagingSenderId: "817888252022",
    appId: "1:817888252022:web:2054515e324f60d460cf57",
    measurementId: "G-RXQ64RHV8T"
};

export const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.addScope('profile');
googleAuthProvider.addScope('email');
googleAuthProvider.addScope('https://www.googleapis.com/auth/gmail.readonly');
googleAuthProvider.addScope('https://www.googleapis.com/auth/gmail.modify');

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
// Export auth
export const auth = getAuth(firebaseApp);
// Export functions
export const functions = getFunctions(firebaseApp);

if (location.hostname === "localhost") {
    connectAuthEmulator(auth, "http://localhost:9099");
    connectFunctionsEmulator(functions, "localhost", 5001);
}