import { getRedirectResult, signInWithRedirect, signOut } from 'firebase/auth';
import { defineStore } from 'pinia';
import { watch } from 'vue';
import { useCurrentUser } from 'vuefire';
import { auth, functions, googleAuthProvider } from '@/firebase';
import { useRoute, useRouter } from 'vue-router';
import { httpsCallable } from 'firebase/functions';

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application

export const useUserStore = defineStore('user', () => {
    const route = useRoute();
    const router = useRouter(); // Initialize the router object

    const user = useCurrentUser();
    // const error = ref(null);

    // Google Authentication
    function googleSigninPopup() {
        signInWithRedirect(auth, googleAuthProvider);
    }

    function googleSignOut() {
        console.log('Signing out');
        signOut(auth);
    }

    /*
    The result object:
{
    "user": {
        "uid": "BzavNHc2I3CquPiVRVkJDlP7QHC2",
        "email": "dalys@chokladboll.se",
        "emailVerified": true,
        "displayName": "Martin Lissmats",
        "isAnonymous": false,
        "photoURL": "https://picsum.photos/200",
        "providerData": [
            {
                "providerId": "google.com",
                "uid": "6919450819605319600184680323197578820216",
                "displayName": "Martin Lissmats",
                "email": "dalys@chokladboll.se",
                "phoneNumber": null,
                "photoURL": "https://picsum.photos/200"
            }
        ],
        "stsTokenManager": {
            "refreshToken": "eyJfQXV0aEVtdWxhdG9yUmVmcmVzaFRva2VuIjoiRE8gTk9UIE1PRElGWSIsImxvY2FsSWQiOiJCemF2TkhjMkkzQ3F1UGlWUlZrSkRsUDdRSEMyIiwicHJvdmlkZXIiOiJnb29nbGUuY29tIiwiZXh0cmFDbGFpbXMiOnt9LCJwcm9qZWN0SWQiOiJoaXAtcHJpbmNpcGxlLTQxMjIxNCJ9",
            "accessToken": "eyJhbGciOiJub25lIiwidHlwIjoiSldUIn0.eyJuYW1lIjoiTWFydGluIExpc3NtYXRzIiwicGljdHVyZSI6Imh0dHBzOi8vcGljc3VtLnBob3Rvcy8yMDAiLCJlbWFpbCI6ImRhbHlzQGNob2tsYWRib2xsLnNlIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF1dGhfdGltZSI6MTcwNzU0OTU0MSwidXNlcl9pZCI6IkJ6YXZOSGMySTNDcXVQaVZSVmtKRGxQN1FIQzIiLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImRhbHlzQGNob2tsYWRib2xsLnNlIl0sImdvb2dsZS5jb20iOlsiNjkxOTQ1MDgxOTYwNTMxOTYwMDE4NDY4MDMyMzE5NzU3ODgyMDIxNiJdfSwic2lnbl9pbl9wcm92aWRlciI6Imdvb2dsZS5jb20ifSwiaWF0IjoxNzA3NTQ5NTQxLCJleHAiOjE3MDc1NTMxNDEsImF1ZCI6ImhpcC1wcmluY2lwbGUtNDEyMjE0IiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2hpcC1wcmluY2lwbGUtNDEyMjE0Iiwic3ViIjoiQnphdk5IYzJJM0NxdVBpVlJWa0pEbFA3UUhDMiJ9.",
            "expirationTime": 1707553141693
        },
        "createdAt": "1706854413879",
        "lastLoginAt": "1707549541685",
        "apiKey": "AIzaSyBCOHhXgLnO3_jTRFAGTqBRfRLooDONKXo",
        "appName": "[DEFAULT]"
    },
    "providerId": "google.com",
    "_tokenResponse": {
        "kind": "identitytoolkit#VerifyAssertionResponse",
        "context": "",
        "providerId": "google.com",
        "displayName": "Martin Lissmats",
        "fullName": "Martin Lissmats",
        "email": "dalys@chokladboll.se",
        "emailVerified": true,
        "photoUrl": "https://picsum.photos/200",
        "rawUserInfo": "{\"granted_scopes\":\"openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email\",\"id\":\"6919450819605319600184680323197578820216\",\"name\":\"Martin Lissmats\",\"verified_email\":true,\"locale\":\"en\",\"email\":\"dalys@chokladboll.se\",\"picture\":\"https://picsum.photos/200\"}",
        "federatedId": "https://accounts.google.com/6919450819605319600184680323197578820216",
        "oauthAccessToken": "FirebaseAuthEmulatorFakeAccessToken_google.com",
        "oauthIdToken": "{\"sub\":\"6919450819605319600184680323197578820216\",\"iss\":\"\",\"aud\":\"\",\"exp\":0,\"iat\":0,\"name\":\"Martin Lissmats\",\"email\":\"dalys@chokladboll.se\",\"email_verified\":true,\"picture\":\"https://picsum.photos/200\"}",
        "localId": "BzavNHc2I3CquPiVRVkJDlP7QHC2",
        "idToken": "eyJhbGciOiJub25lIiwidHlwIjoiSldUIn0.eyJuYW1lIjoiTWFydGluIExpc3NtYXRzIiwicGljdHVyZSI6Imh0dHBzOi8vcGljc3VtLnBob3Rvcy8yMDAiLCJlbWFpbCI6ImRhbHlzQGNob2tsYWRib2xsLnNlIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF1dGhfdGltZSI6MTcwNzU0OTU0MSwidXNlcl9pZCI6IkJ6YXZOSGMySTNDcXVQaVZSVmtKRGxQN1FIQzIiLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImRhbHlzQGNob2tsYWRib2xsLnNlIl0sImdvb2dsZS5jb20iOlsiNjkxOTQ1MDgxOTYwNTMxOTYwMDE4NDY4MDMyMzE5NzU3ODgyMDIxNiJdfSwic2lnbl9pbl9wcm92aWRlciI6Imdvb2dsZS5jb20ifSwiaWF0IjoxNzA3NTQ5NTQxLCJleHAiOjE3MDc1NTMxNDEsImF1ZCI6ImhpcC1wcmluY2lwbGUtNDEyMjE0IiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2hpcC1wcmluY2lwbGUtNDEyMjE0Iiwic3ViIjoiQnphdk5IYzJJM0NxdVBpVlJWa0pEbFA3UUhDMiJ9.",
        "refreshToken": "eyJfQXV0aEVtdWxhdG9yUmVmcmVzaFRva2VuIjoiRE8gTk9UIE1PRElGWSIsImxvY2FsSWQiOiJCemF2TkhjMkkzQ3F1UGlWUlZrSkRsUDdRSEMyIiwicHJvdmlkZXIiOiJnb29nbGUuY29tIiwiZXh0cmFDbGFpbXMiOnt9LCJwcm9qZWN0SWQiOiJoaXAtcHJpbmNpcGxlLTQxMjIxNCJ9",
        "expiresIn": "3600"
    },
    "operationType": "signIn"
}
    */
    // Handle the redirect result after sign in
    // TODO: Handle this gracefully? Only execute if the user is on the login page?
    getRedirectResult(auth).then(function (result) {
        if (!result) {
            return;
        }
        console.log('Redirect result', result);

        // Use the result object above to get the user's information
        if (result.user && result.user.stsTokenManager) {
            console.log('User stsTokenManager', result.user.stsTokenManager);
            var token = result.user.stsTokenManager.accessToken;
            // The signed-in user info.
            // var user = result.user;
            console.log('Token', token);

            // Call a Firebase Function to set up gmail.users.watch
            const watchGmail = httpsCallable(functions, 'watchGmail');
            watchGmail({ 'stsTokenManager': result.user.stsTokenManager }).then(function (result) {
                console.log('Result', result);
            }).catch(function (error) {
                // Getting the Error details.
                var code = error.code;
                var message = error.message;
                var details = error.details;
                console.log('Error', code, message, details);
            });
        }
    }).catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log('Error', errorCode, errorMessage);
        console.log('Error', error);
    });

    watch(user, async (currentUser, previousUser) => {
        // redirect to login if they logout and the current
        // route is only for authenticated users
        if (!currentUser && previousUser && route.meta.requiresAuth) {
            console.log('User logged out, redirecting to login');
            return router.push({ name: 'landing' });
        }
        // redirect the user if they are logged in but were
        // rejected because the user wasn't ready yet, logged in
        // then got back to this page
        if (currentUser) {
            console.log('User logged in, redirecting to dashboard');
            return router.push({ name: 'dashboard' });
        }
    });

    // Must return all state properties
    return { user, googleSigninPopup, googleSignOut };
});