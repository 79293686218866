<template>
    <!--
      This example requires updating your template:
  
      ```
      <html class="h-full bg-gray-50">
      <body class="h-full">
      ```
    -->
    <div class="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img class="mx-auto h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt="Your Company" />
        </div>

        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                <h2 class="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Continue with
                    your<br />
                    Google Account
                </h2>
                <div class="border border-gray-200 bg-white px-4 py-5 sm:px-6 my-6">
                    <h3 class="text-base font-semibold leading-6 text-gray-900">First time?</h3>
                    <p class="mt-2 text-gray-500 text-sm">CondenseMail will ask for permissions to read and modify your
                        emails.
                    </p>
                    <p class="mt-2 text-gray-500 text-sm">
                        This is needed for CondenseMail to work.
                    </p>
                </div>
                <div>
                    <div class="mt-10 grid gap-4">
                        <a href="#" @click="googleSigninPopup"
                            class="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent">
                            <svg class="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                                <path
                                    d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                                    fill="#EA4335" />
                                <path
                                    d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                                    fill="#4285F4" />
                                <path
                                    d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                                    fill="#FBBC05" />
                                <path
                                    d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                                    fill="#34A853" />
                            </svg>
                            <span class="text-sm font-semibold leading-6">Continue with Google</span>
                        </a>
                    </div>
                </div>
            </div>

            <p class="mt-10 text-center text-sm text-gray-500">
                <a href="#" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Privacy Policy</a>
                &middot;
                <a href="#" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Terms & Conditions</a>
            </p>
        </div>
    </div>
</template>
  
<script setup>
import { useUserStore } from '@/stores/user';

const { googleSigninPopup } = useUserStore();

</script>