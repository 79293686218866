import App from '@/App.vue';
import GoogleAuth from '@/GoogleAuth.vue';
import { firebaseApp } from '@/firebase';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createRouter, createWebHistory } from 'vue-router';
import { VueFire, VueFireAuth } from 'vuefire';
import LandingPage from './LandingPage.vue';
import DashboardPage from './DashboardPage.vue';
import { getCurrentUser } from 'vuefire';


const routes = [
    { name: 'landing', path: '/', component: LandingPage, meta: { requiresAuth: false } },
    { name: 'dashboard', path: '/dashboard', component: DashboardPage, meta: { requiresAuth: true } },
    { name: 'login', path: '/login', component: GoogleAuth, meta: { requiresAuth: false } },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);

app.use(VueFire, {
    firebaseApp,
    modules: [
        VueFireAuth(),
    ],
});


router.beforeEach(async (to) => {
    if (to.meta.requiresAuth) {
        const currentUser = await getCurrentUser();

        if (!currentUser) {
            return {
                path: '/login',
                query: {
                    // we keep the current path in the query so we can
                    // redirect to it after login with
                    // `router.push(route.query.redirect || '/')`
                    redirect: to.fullPath,
                },
            };
        }
    }
});

// Redirect to dashboard if logged in
router.beforeEach(async (to) => {
    if (to.name === 'login') {
        const currentUser = await getCurrentUser();

        if (currentUser) {
            return {
                path: '/dashboard',
            };
        }
    }
});

app.use(router);
app.mount('#app');
